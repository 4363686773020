import React, { useState, useEffect } from 'react'
import marked from 'marked'


const SectionOfferTile = ({ selectedMaterialData, setParams, moviesFromDb }) => {
  const { title, description, price, image } = selectedMaterialData.fields;

  const img = image.fields.file.url;
  const [ movieCount, setMovieCount ] = useState(0);
  const [ movieTime, setMovieTime ] = useState('');

  const actualDescription = marked(description);

  useEffect(() => {
    const movies = moviesFromDb.filter(item => {
      return item.title
        ? item.title.toLowerCase() === title.toLowerCase() ||
        (title === 'Bioetyka i Prawo' &&
          item.title === 'Prawo medyczne') ||
        (title === 'Gastroenterologia' && item.title === 'Gastrologia')
        : false;
    });
    console.log("movies :", movies)
    setMovieCount(movies.length);
    const duration = movies.reduce((acc, movie) => acc + movie.time, 0);
    const H = Math.floor(duration / 3600);
    const M = Math.floor(duration / 60) % 60;
    H > 0 ? setMovieTime(`${H} h ${M} min`) : setMovieTime(`${M} min`);
  }, [ title,moviesFromDb ]);

  return (
    <div className="product-listing--section ">
      <span className="product-listing__mobile-info">?</span>
      <div className="product__image--wrapper">
        <img src={ img } alt={ title } className="product__image" />
      </div>
      <div className="product-background" />
      <div className="product__badge">
        <div className="badge__background" />
        <div className="text-title">
          <h4>{ title }</h4>
        </div>
        <p>
          { movieCount } filmów, czas: { movieTime }
        </p>
      </div>
      <div className="product-listing--section__inner-wrapper">
        <div className="product-listing--section__additional-info">
          <section
            dangerouslySetInnerHTML={ { __html: actualDescription } }
          ></section>
        </div>
        <div className="tile__footer">
          <div className="tile__footer--background" />
          <p className="price">Cena:&nbsp;{ price }&nbsp;zł</p>
          <p
            onClick={ () =>
              setParams({
                type: title.toLowerCase(),
                paymentChoice: 'Płatność jednorazowa'
              })
            }
            className="button-link btn--section  button-link__secondary--reversed btn grid-content-right button-link__space-standard"
          >
            Kup&nbsp;teraz
          </p>
        </div>
      </div>
    </div>
  );
};


export default SectionOfferTile
