import React from 'react'
import SectionOfferTile from './selectedOfferTile'
import demoPlay from '../../img/demo play icon.png';

const SelectedMaterials = ({ contentfullData, myRef2, setParams, moviesFromDb }) => {
  const selectedMaterialsData = contentfullData.filter((item) => item.sys.contentType.sys.id === "selectedMaterials")
  console.log("SelectedMaterials :", moviesFromDb)
  return (
    <>
      <h2 ref={ myRef2 }>Wybrane materiały:</h2>
      <p className="highlight">
        Dziś możesz wykupić dostęp do wybranej dziedziny. Chcesz powtórkę z
        wybranego zagadnienia?
            <br />
            Interesuje Cię wybrany temat do powtórzeń? Wybierz dziedzinę i
            zdobywaj wiedzę korzystając z naszych materiałów filmowych, mnemonik
            i baz pytań z danego działu medycyny.
          </p>

      <div className="wrapper--oferta grid-flex-row">
        <div className="fb45">
          <div className="offer__heading">
            <img src={ demoPlay } alt="" />

            <div className="offer__heading--inner">
              <h3>Zobacz materiał demo</h3>{ ' ' }
            </div>
          </div>
        </div>

        <div className="fb45">
          <a
            href="https://lekkikurs.pl/anestezjologia.php?titleID=343742807"
            className="btn button-link button-link__demo"
          >
            Obejrzyj materiał demonstracyjny
              </a>
        </div>
      </div>
      { selectedMaterialsData.map((selectedMaterialData, i) => (
        <SectionOfferTile { ...{ selectedMaterialData, setParams, moviesFromDb, key: i } } />
      )) }
    </>
  )
}

export default SelectedMaterials
