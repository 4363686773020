import React, { useState, useEffect, useRef } from 'react';
import { useLocalStorage } from '../hooks';
import Offers from '../components/Offers';
import Banner from '../components/banner';
import YoursBenefits from '../components/yoursBenefits';
import TimeAccess from '../components/timeAccess';
import SelectedMaterials from '../components/selectedMaterials';
import { client } from '../client';
import axios from 'axios';
const baseUrl = 'https://lekkikurs.pl';

const scrollToRef = (ref) =>
  window.scrollTo({
    top: ref.current.offsetTop,
    left: 0,
    behavior: 'smooth',
  });

function Zakupy() {
  const [redirect, setRedirect] = useState(false);
  const [params, setParams] = useState([]);
  const myRef1 = useRef(null);
  const myRef2 = useRef(null);
  const executeScroll = () => scrollToRef(myRef1);
  const url = baseUrl + '/includes/php/set_newPaymentValue.php';
  const [excludedSections, setExludedSections] = useState([]);
  const [moviesFromDb, updateMoviesFromDb] = useState([[]]);
  const [contentfullData, setContentfullData] = useLocalStorage('newData', []);

  useEffect(() => {
    client.getEntries().then((res) => {
      setContentfullData(res.items);
    });
  }, []);

  useEffect(() => {
    axios
      .get(baseUrl + '/includes/php/video_list2.php', {
        credentials: 'same-origin',
      })
      .then((res) => {
        console.log("axios: ", res.data);
        return res.data;
      })
      .then((data) => updateMoviesFromDb(data))
      .catch((err) => console.log(err));
  }, []);

  // send data to backend
  useEffect(() => {
    if (redirect) {
      axios
        .post(url, {
          credentials: 'same-origin',
          type: params.type,
          paymentChoice: params.paymentChoice,
        })
        .then((res) => {
          if (res.data === 'Success') {
            window.location.href = baseUrl + '/zakupyfinal_payment';
          } else if (res.data === 'session does not exists!') {
            window.location.href = baseUrl + '/loginNew.php?zakupy';
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [redirect, params.paymentChoice, params.type, url]);

  //if everythink ok send data
  useEffect(() => {
    if (params.type) {
      setRedirect(true);
    }
  }, [params]);

  // get list of already pucharsed sections
  useEffect(() => {
    axios
      .get(baseUrl + '/includes/php/getAccessDetails1.php', {
        credentials: 'same-origin',
      })
      .then((res) => res.data)
      .then((data) =>
        setExludedSections(Object.keys(data).filter((key) => !!data[key]))
      )
      .catch((err) => console.log(err));
  }, []);

  const openOferta = () => {
    executeScroll();
  };

  return (
    <div className="main-Content">
      <div className="set-background"></div>
      <div className="wrapper  relative">
        {contentfullData !== [] && (
          <>
            <Banner {...{ openOferta, scrollToRef, myRef2 }} />
            <Offers {...{ contentfullData }} />
            <YoursBenefits {...{ contentfullData }} />
            <TimeAccess {...{ myRef1, contentfullData, setParams }} />
            <SelectedMaterials
              {...{ contentfullData, myRef2, setParams, moviesFromDb }}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default Zakupy;
