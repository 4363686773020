import React , { useState, useEffect } from 'react';

const StartAnimation = () => {
  return (
    <div className="game__animation-wrapper">
      <span className="game__animation--inner element-1"> 3 </span>
      <span className="game__animation--inner element-2"> 2 </span>
      <span className="game__animation--inner element-3"> 1</span>
      <span className="game__animation--inner element-4"> START!!</span>
    </div>
    )
}

    




export default StartAnimation;