import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import TopicList from "./topics";
import Loader from "./loader";
import style from "./style.css";
import Header from "../../components/grywalizacja/header";
import axios from "axios";
import diece from "../../img/diece.png";
import clock from "../../img/clock.png";
import winImg from "../../img/footer.png";
import looseImg from "../../img/heart.png";
import StartAnimation from "./animation";

const choices = [
  { Boolean },
  { Boolean },
  { Boolean },
  { Boolean },
  { Boolean },
  { Boolean },
  { Boolean },
  { Boolean },
  { Boolean },
  { Boolean },
];

const opponentChoices = [
  { Boolean },
  { Boolean },
  { Boolean },
  { Boolean },
  { Boolean },
  { Boolean },
  { Boolean },
  { Boolean },
  { Boolean },
  { Boolean },
];

function Gra() {
  const url = "https://lekkikurs.pl/includes/php/grywalizacja/";

  //gozik start - rozpoczyna szukanie przeciwnika
  const [isStart, startGame] = useState(false);
  // szuka przeciwnika, zapisuje id pojedynku
  const [isOponent, searchOponent] = useState(false);
  // ustawia tytuł
  const [chooseTopic, isChooseTopic] = useState(false);
  const [topic, setTopic] = useState(false);
  const [opponentName,setYourOponent] = useState('');

  const [questions, setQuestions] = useState(false);
  const [isAnimation, setAnimation] = useState(false);
  const [timeCounter, setTimeCounter] = useState(0);
  const [timeLeft, setTimeLeft] = useState(300);
  const [question, getQuestion] = useState(0);
  const [choice, setChoice] = useState("");
  const [isGameReady, setGameReady] = useState(false);
  const [isGameEnd, setGameEnd] = useState(false);
  const [gameResult, setGameResult] = useState(false);
  const [checkAnswers, setCheckAnswers] = useState(false);

  const topicList = [
    "Anestezjologia",
    "Bioetyka i Prawo",
    "Chirurgia",
    "Endokrynologia",
    "Gastrologia",
    "Ginekologia",
    "Hematologia",
    "Kardiologia",
    "Med.ratunkowa",
    "Med. rodzinna",
    "Nefrologia",
    "Orzecznictwo",
    "Pediatria",
    "Położnictwo",
    "Pulmonologia",
    "Psychiatria",
    "Reumatologia",
    "Zdrowie publiczne",
  ];

  //register
  useEffect(() => {
    if (isStart && !chooseTopic) {
      // searchOponent(51);
      // isChooseTopic(true);
      const interval = setInterval(() => {
        const result = axios
          .post(url + "signIn.php", {
            credentials: "same-origin",
            id: isOponent,
            headers: {
              "Content-Type":
                "application/x-www-form-urlencoded; charset=UTF-8",
            },
          })
          .then((res) => {
            console.log(res.data.id);
            searchOponent(res.data.id);
            setYourOponent(res.data.opponenet);
            if (res.data.waitORfind == "start") {
              isChooseTopic(true);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }, 2000);
      return () => clearInterval(interval);
    }
  });

  //set topic
  useEffect(() => {
    if (topic !== false) {
      const result = axios
        .post(url + "setQuestions.php", {
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          id: isOponent,
          title: topic,
        })
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });
    }
  }, [topic]);

  //get questions
  useEffect(() => {
    if (topic !== false && !questions) {
      const intervalQuestion = setInterval(() => {
        const result = axios
          .post(url + "getQuestions.php", {
            credentials: "same-origin",
            headers: {
              "Content-Type":
                "application/x-www-form-urlencoded; charset=UTF-8",
            },
            id: isOponent,
            title: topic,
          })
          .then((res) => {
            console.log(res);
            if (res.data != "wait" && res.data != "error") {
              setQuestions(res.data);
              setGameReady(true);
               setTimeout(() => {
                setAnimation(true);;
            }, 4000);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }, 5000);
      return () => clearInterval(intervalQuestion);
    }
  });

  let IntervalTimeLeft;

  const check = () => {
    let finishMode = false;
    questions[question].userChoice = choice;
    if (choice === questions[question].correct) {
      choices[question] = true;
    } else {
      choices[question] = false;
    }
    if (question == 9) {
      setGameEnd(true);
      finishMode = true;
    }

    const result = axios
      .post(url + "setChoices.php", {
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        id: isOponent,
        choices: choices,
        finish: finishMode,
      })
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });

    getQuestion(question + 1);
    let radioEl = document.getElementsByName("choice");
    radioEl.forEach((rate) => {
      if (rate.checked) {
        rate.checked = false;
      }
    });
  };

  // get oponent choices
  useEffect(() => {
    if (questions && !isGameEnd) {
      const intervalQuestion = setInterval(() => {
        const result = axios
          .post(url + "getChoices.php", {
            credentials: "same-origin",
            headers: {
              "Content-Type":
                "application/x-www-form-urlencoded; charset=UTF-8",
            },
            id: isOponent,
          })
          .then((res) => {
            if (res.data.result == "finish") {
              const newData = JSON.parse(res.data.choices);
              newData.map((value, index) => (opponentChoices[index] = value));
              setGameEnd(true);
            } else if (res.data.result !== "finish" && res.data !== "error" && res.data.choices) {
              const newData = JSON.parse(res.data.choices);
              newData.map((value, index) => (opponentChoices[index] = value));
              console.log(opponentChoices);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }, 1000);
      return () => clearInterval(intervalQuestion);
    }
  });

  useEffect(() => {
    if (isGameEnd) {
      let player = 0;
      let indexPlayer = 0;
      let opponent = 0;
      let indexOpponent = 0;
      let result;
      choices.map((value) =>
        value == true ? player++ : value == false ? indexPlayer++ : null
      );
      opponentChoices.map((value) =>
        value == true ? opponent++ : value == false ? indexOpponent++ : null
      );
      result =
        player > opponent
          ? "wygrałeś :)"
          : player !== opponent
          ? "przegrałeś ;("
          : indexPlayer > indexOpponent
          ? "wygrałeś :)"
          : indexPlayer == indexOpponent
          ? "remis :] "
          : "przegrałeś;( ";

      setGameResult({
        player: player,
        opponent: opponent,
        result: result,
      });
    }
  }, [isGameEnd]);

  useEffect(() => {
    if (isGameReady && !isGameEnd) {
      IntervalTimeLeft = setTimeout(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
    }
  });

  useEffect(() => {
    if (timeLeft == 0) {
      setGameEnd(true);
    }
  });

  const loadQuestion = (el) => {
    if (gameResult) {
      setCheckAnswers(true);
      getQuestion(el);
    }
  };

  const additionalClass = (el) => {
    let label = "question--label";
    if (el === questions[question].userChoice) {
      if (el === questions[question].correct) {
        label += " question--label-correct";
      } else {
        label += " question--label-false";
      }
    }
    if (el === questions[question].correct) {
      label += " question--label-correct";
    }
    return label;
  };

  return (
    <div className="game-section-wrapper ">
      <div className="wrapper">
        <Header />
        <div className="game__wrapper ">
          <div className="game__gameboard--title">
            <img src={diece} />
            <p>Grywalizacja </p>{" "}
            <span className="question--time-left">
              <img src={clock} /> {timeLeft}s
            </span>
          </div>
          <div className="game__gameboard">
            {!isStart ? (
              <div>
                <p>
                  Witamy Cię w GRYWALIZACJI. To nowa funkcja platformy LEKkiego Kursu. 
                  Ucz się przez zabawę i wygrywaj z innymi Uczestnikami kursu!
                </p>
                <p>
                  Kliknij w poniższy przycisk, znajdź przeciwnika i rozpocznij rywalizację! 
                  Pamiętaj! Wygrywa najlepszy i najszybszy!
                </p>
                
                <div
                  className="game__gameboard--start btn btn--game button-link button-link-sm"
                  onClick={() => startGame(true)}
                >
                  {" "}
                  Szukaj przeciwnika{" "}
                </div>
              </div>
            ) : !chooseTopic ? (
              <Loader description={"Szukamy przeciwnika."} />
            ) : !topic ? (
              <div>
                <h4> Udało się, znalazł się uczestnik. <strong>{opponentName}</strong> jest chętny do pojedynku. Teraz wygenerujemy dla Was rozgrywkę. Każdy z Was musi wybrać dział z którego pojawią się pytania.</h4>
                <ul className="game__gameboard--selector">
                  {topicList.map((item, i) => (
                    <li
                      key={i}
                      onClick={() => setTopic(item)}
                      className="topic-select"
                    >
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            ) : !isGameReady ? (
              <Loader description="Twój przeciwnik jeszcze wybiera swój dział..." />
            ) : !isAnimation ?(
              <StartAnimation />
            )
            : !isGameEnd ? (
              <div className="cover-parent">
                <h2>{questions[question].quizTitle}</h2>
                <h3 className="question-title--wrapper">
                  <span className="question-title--counter">
                    {question + 1}.
                  </span>
                  {questions[question].question.split("</br>").map((line) => (
                    <p>{line}</p>
                  ))}
                </h3>
                <label className="question--label">
                  <input
                    type="radio"
                    name="choice"
                    onClick={() => setChoice(questions[question].choice1)}
                  /><span className="question--circle" />
                  A) {questions[question].choice1}
                </label>
                <label className="question--label">
                
                  <input
                    type="radio"
                    name="choice"
                    onClick={() => setChoice(questions[question].choice2)}
                  /><span className="question--circle" />
                  B) {questions[question].choice2}
                </label>
                <label className="question--label">
                
                  <input
                    type="radio"
                    name="choice"
                    onClick={() => setChoice(questions[question].choice3)}
                  /><span className="question--circle" />
                  C) {questions[question].choice3}
                </label>
                <label className="question--label">
                
                  <input
                    type="radio"
                    name="choice"
                    onClick={() => setChoice(questions[question].choice4)}
                  /><span className="question--circle" />
                  D) {questions[question].choice4}
                </label>
                <label className="question--label">
                
                  <input
                    type="radio"
                    name="choice"
                    onClick={() => setChoice(questions[question].choice5)}
                  /><span className="question--circle" />
                  E) {questions[question].choice5}
                </label>
                <button
                  className="game__gameboard--start btn btn--game button-link button-submit"
                  onClick={() => check()}
                >
                  ok
                </button>
              </div>
            ) : !gameResult ? (
              <Loader description="Czekamy na wynik przeciwnika." />
            ) : !checkAnswers ? (
              // <GameResult description="Czekamy na wynik przeciwnika2." />
              <div>
                <h1>{gameResult.result}</h1>
                {gameResult.result == "wygrałeś :)" ? (
                  <img src={winImg} />
                ) : (
                  <img src={looseImg} />
                )}
              </div>
            ) : (
              <div className="cover-parent">
                <h2>{questions[question].quizTitle}</h2>
                <h3 className="question-title--wrapper">
                  <span className="question-title--counter">
                    {question + 1}.
                  </span>
                  {questions[question].question.split("</br>").map((line) => (
                    <p>{line}</p>
                  ))}
                </h3>
                <label className={additionalClass(questions[question].choice1)}>
                  <input
                    type="radio"
                    name="choice"
                    checked={
                      questions[question].choice1 ==
                      questions[question].userChoice
                        ? true
                        : false
                    }
                  /><span className="question--circle" />
                  A) {questions[question].choice1}
                </label>
                <label className={additionalClass(questions[question].choice2)}>
                  <input
                    type="radio"
                    name="choice"
                    checked={
                      questions[question].choice2 ==
                      questions[question].userChoice
                        ? true
                        : false
                    }
                  /><span className="question--circle" />
                  B) {questions[question].choice2}
                </label>
                <label className={additionalClass(questions[question].choice3)}>
                  <input
                    type="radio"
                    name="choice"
                    checked={
                      questions[question].choice3 ==
                      questions[question].userChoice
                        ? true
                        : false
                    }
                  /><span className="question--circle" />
                  C) {questions[question].choice3}
                </label>
                <label className={additionalClass(questions[question].choice4)}>
                  <input
                    type="radio"
                    name="choice"
                    checked={
                      questions[question].choice4 ==
                      questions[question].userChoice
                        ? true
                        : false
                    }
                  /><span className="question--circle" />
                  D) {questions[question].choice4}
                </label>
                <label className={additionalClass(questions[question].choice5)}>
                  <input
                    type="radio"
                    name="choice"
                    checked={
                      questions[question].choice5 ==
                      questions[question].userChoice
                        ? true
                        : false
                    }
                  /><span className="question--circle" />
                  E) {questions[question].choice5}
                </label>
              </div>
            )}
          </div>

          <div className="game__data">
            {!gameResult ? (
              <h3>Twoje odpowiedzi</h3>
            ) : (
              <h3>
                Twój wynik : {gameResult.player} / 10 <br />
                <span className="title-info">
                  Kliknij w numer i sprawdź odpowiedzi
                </span>
              </h3>
            )}

            {choices.map((keyName, i) => (
              <div
                className={`game__data--num ${
                  i === question ? "game__data--num-selected" : ""
                } ${!gameResult ? "" : "game__data--abble-to-click"}`}
                key={"num" + i}
                onClick={() => loadQuestion(i)}
              >
                <span
                  className={
                    keyName === true
                      ? "question__choice question__choice--correct"
                      : keyName === false
                      ? "question__choice question__choice--wrong"
                      : "question__choice"
                  }
                >
                  {i + 1}
                </span>
              </div>
            ))}
          </div>

          <div className="game__data">
            {!gameResult ? (
              <h3>Odpowiedzi twojego przeciwnika</h3>
            ) : (
              <h3>Wynik przeciwnika: {gameResult.opponent} / 10</h3>
            )}
            {opponentChoices.map((keyName, i) => (
              <div className={"game__data--num"} key={"num" + i}>
                <span
                  className={
                    keyName === true
                      ? "question__choice question__choice--correct"
                      : keyName === false
                      ? "question__choice question__choice--wrong"
                      : "question__choice"
                  }
                >
                  {i + 1}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Gra;
