import React from 'react'
import cloud from '../../img/cloud.png';
import woman from '../../img/woman.png';

const Banner = ({openOferta, scrollToRef, myRef2}) => {
  return (
    <div className="banner">
      <div className="banner__img">
        <div className="woman">
          <img src={ woman } alt="woman" />
        </div>
        <div className="cloud">
          <img src={ cloud } alt="cloud" />
        </div>
      </div>
      <div className="banner__text mobileNone">
        <div className="actual-text">
          <p>
            <span className="highlight">Już teraz</span> poza dostępem
            czasowym
          </p>
          <p>
            do Platformy możesz wykupić również <span>dostęp</span>
          </p>
          <p>
            <span className="highlight">
              do materiałów z wybranej dziedziny.
            </span>
          </p>
        </div>
      </div>
      <div className="banner__buttons">
        <div
          className="btn button-link button-link__main"
          onClick={ () => openOferta() }
        >
          dostęp czasowy
        </div>
        <div
          className="btn button-link button-link__secondary"
          onClick={ () => scrollToRef(myRef2) }
        >
          wybrane materiały
        </div>
      </div>
    </div>
  );
};


export default Banner
