import React , { useState, useEffect } from 'react';

function TopicList({ topic, setTopic }){
  const topicList = ['Anestezjologia', 'Bioetyka i Prawo', 'Chirurgia', 'Endokrynologia', 'Gastrologia','Ginekologia'];
  
   return (
    <div>
    <p> wybierz teamt z listy</p>
    <ul>
    {topicList.map((item,i) => <li key={i} onClick={() => setTopic(item)}>{item}</li>)}
    </ul>
    </div>
  );
}

export default TopicList;