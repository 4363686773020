import React from 'react'

const ScriptTile = ({ title, price, img, accessibility, available, setParams }) => {

  return (
    <div className="product-listing--script">
      <img src={ img } alt="zamów skrypt" className="script-tile__img" />
      <div className="product-listing--script__inner-wrapper">
        <div className="script__offer">
          <h3>{ title }</h3>
        </div>
        <div className="script__info">
          Cena: {price} zł
          <p
            onClick={
              () => !available ? alert(`UWAGA!! Zakup skryptu możliwy ${accessibility}`) :
              setParams({
                type: 'skrypt',
                paymentChoice: 'Płatność jednorazowa',
              })
            }
            className="button-link  button-link__secondary--reversed btn grid-content-right button-link__space-standard"
          >
            Dostępny od {accessibility}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ScriptTile
