import React from 'react'

const BenefitsTile = ({ benefitTileData }) => {
  const { title, titleBottom, image } = benefitTileData.fields;
  const img = image.fields.file.url
  return (
    <div className="icon-tile">
      <img
        className="icon-tile__img"
        src={ img }
        alt="benefit-tile-img"
      />
      <div className="icon-tile__text">
        {title}
          <br /> {titleBottom}
      </div>
    </div>
  )
}

export default BenefitsTile
