import React, { useState, useEffect } from 'react';
import steps from '../img/steps.png';
import info01 from '../img/info-1.png';
import info02 from '../img/info-2.png';
import info03 from '../img/info-3.png';
import info04 from '../img/info-4.png';
import info05 from '../img/info-5.png';
import info06 from '../img/info-6.png';
import info07 from '../img/info-7.png';
import info08 from '../img/info-8.png';
import info09 from '../img/info-9.png';
import chart1 from '../img/chart1.png';
import chart2 from '../img/chart2.png';



function Opinie() {
  
  const [displayInfo1, setDisplayInfo1] = useState(false);
  const [displayInfo2, setDisplayInfo2] = useState(false);
  const [displayInfo3, setDisplayInfo3] = useState(false);
  
  
  return (
    <div className="main-Content">
      <div className="main-header__bottom section-home ">
        <div className="wrapper">
          <div className="grid-col-8 grid-content-right">
            <div className="grid-col-7 section-home__content ">
              <p className="section-home__cezar"><strong>95%</strong> zdawalności LEK-u</p>
              <h1>Zarejestruj się już dziś</h1>
              <p className='text-shadow'>sprawdź darmowe demo</p>
              <div className="button-link button-link-md button-link__secondary btn grid-content-center"><a href="https://lekkikurs.pl/filmy.php" > DEMO </a></div>
            </div>
          </div>
        </div>
      </div>
      
      <div className="section-home__content">
        <div className="content-grid__section section-home__background-top">
          <div className="line-separator line-separator-top">
          <span className="text-info text-uppercase"> poznaj szczegóły</span>
          <h1 className="grid-col-12">LEKki Kurs - co to?</h1></div>
          <p className="grid-col-4 grid-content-center section-title__info text-info">LEKki Kurs to kurs stworzony przez lekarzy dla lekarzy i studentów medycyny. Nauka z platformą i skryptem to gwarancja na efektywne przyswojenie wiedzy potrzebnej do zdania LEKu oraz egzaminów studenckich. Autorskie grafiki mnemotechniczne są szalone, groteskowe i potrafią zapaść w pamięć. Na platformie ulegają ożywieniu za pomocą animacji poklatkowej.</p>
          <div className="wrapper">
            <div className="grid-col-4 info-wrapper">
              <div className={`info-section__blue-img ${displayInfo1 ? 'toggle' : ''}`}>
                <img src={info01} alt="zdjęcie informacyjne" className="btn" onClick={() => setDisplayInfo1(true)} />
                <h2 className="section-title">Platforma</h2>
                <div onClick={() => setDisplayInfo1(true)} ><span className="text-info">szczegóły</span></div>
              </div>
              <div className={`info-section__blue-description ${displayInfo1 ? 'toggle' : ''}`}>
                <h3 className="section-title text-uppercase">Platforma</h3>
                <span className="info-section__close" onClick={() => setDisplayInfo1(false)} >x</span>
                <p className="text-info">
                 Platforma LEKkiego Kursu to lek na LEK. Zawiera wykłady, prezentacje, grafiki, pytania, artykuły. Pozwala analizować swoje postępy w nauce. Motywuje do systematycznej pracy. To ogrom wiedzy w jednym miejscu.
                </p>
              </div>
            </div>
            
            <div className="grid-col-4 info-wrapper">
             <div className={`info-section__pink-img ${displayInfo2 ? 'toggle' : ''}`}>
              <img src={info02} alt="zdjęcie informacyjne" className="btn" onClick={() => setDisplayInfo2(true)} />
              <h2 className="section-title">Skrypt</h2>
               <div onClick={() => setDisplayInfo2(true)}  ><span className="text-info" >szczegóły</span></div>
              </div>
              <div className={`info-section__pink-description ${displayInfo2 ? 'toggle' : ''}`}>
                <h3 className="section-title text-uppercase">Skrypt</h3>
                <span className="info-section__close" onClick={() => setDisplayInfo2(false)}  >x</span>
                <p className="text-info">
                 Skrypt zawiera 1000 zagadnień, które wzbogaciliśmy kolorowymi grafikami mnemotechnicznymi. Ogrom wiedzy w jednym miejscu. Brak dostępu do internetu nie będzie problemem, aby nadal korzystać z kursu. Nauka ze skryptem to przyjemnie spędzony czas. 
                </p>
              </div>
            </div>
            <div className="grid-col-4 info-wrapper">
             <div className={`info-section__green-img ${displayInfo3 ? 'toggle' : ''}`}>
              <img src={info03} alt="zdjęcie informacyjne" className="btn" onClick={() => setDisplayInfo3(true)} />
              <h2 className="section-title text-uppercase">Mnemoniki</h2>
               <div onClick={() => setDisplayInfo3(true)}><span className="text-info">szczegóły</span></div>
              </div>
              <div className={`info-section__green-description ${displayInfo3 ? 'toggle' : ''}`}>
                <h3 className="section-title">Mnemoniki</h3>
                <span className="info-section__close" onClick={() => setDisplayInfo3(false)} >x</span>
                <p className="text-info">
                 Mnemoniki to grafiki, które ułatwiają zapamiętywanie, przechowywanie i przypominanie informacji. W LEKkim Kursie znajduje się ponad 1000 grafik mnemotechnicznych. Szokują, zastanawiają, pomagają. Pozwalają na szybkie i efektywne zapamiętywanie poszczególnych działów medycyny. 
                </p>
              </div>
            </div>
          </div>
          <div className="line-separator line-separator-bottom">
            <p className="button-link button-link__danger button-link-md btn">Dowiedz się więcej</p>
          </div>
        </div>
        
        <div className="content-grid__section section-home__step-by-step">
          <p className="text-uppercase text-info">krok po kroku</p>
          <h2 className="text-title">Nauka z LEKkim Kursem <br />krok po kroku</h2>
          <img src={steps}/>
        </div>
        
        <div className="content-grid__section section-home__background-mid">
        <div className="line-separator line-separator-top">
          <p className="text-uppercase text-info">efekty naszej pracy</p>
          </div>
          <h2 className="text-title">Co oferuje LEKki Kurs?</h2>
          
          <div className="wrapper">
            <div className="grid-col-4 grid-flex-column content-wrapper">
            <div className="img-wrapper">
              <img src={info04} alt="zdjęcie informacyjne" className=""/>
              </div>
              <h3 className="text-uppercase">Grafiki i animacje mnemotechniczne</h3>
              <p className="text-info">1000 autorskich grafik <br /> ułatwiających zapamiętywanie. Mnemoniki ożywają na platformie
              </p>
            </div>
            <div className="grid-col-4 grid-flex-column content-wrapper">
            <div className="img-wrapper">
              <img src={info05} alt="zdjęcie informacyjne" className=""/>
              </div>
              <h3 className="text-uppercase">Filmy</h3>
              <p className="text-info">Ponad 60 godzin wykładów. 320 filmów kilkunastominutowych. 10 wykładowców.<br /> Filmoteka LEKkiego Kursu dostarcza wielu emocji.</p>
            </div>
            <div className="grid-col-4 grid-flex-column content-wrapper">
            <div className="img-wrapper">
              <img src={info06} alt="zdjęcie informacyjne" className=""/>
              </div>
              <h3 className="text-uppercase">Kolorowy Skrypt</h3>
              <p className="text-info">Brak zasięgu to nie problem. Skrypt to ponad 500 stron i 1000 zagadnień stanowiący formę powtórki wykładów. Czytelny. Wygodny w użyciu. Idealny do plecaka.</p>
            </div>
            <div className="grid-col-4 grid-flex-column content-wrapper">
            <div className="img-wrapper">
              <img src={info07} alt="zdjęcie informacyjne" className=""/>
              </div>
              <h3 className="text-uppercase">Inteligentna baza pytań </h3>
              <p className="text-info">Ponad 5500 pytań na jednej platformie. Baza pytań podzielona na działy i jednostki chorobowe. Próbne egzaminy. Pytania kontrolujące po każdym wykładzie. Skuteczny sposób na sprawdzenie wiedzy</p>
            </div>
            <div className="grid-col-4 grid-flex-column content-wrapper">
            <div className="img-wrapper">
              <img src={info08} alt="zdjęcie informacyjne" className=""/>
              </div>
              <h3 className="text-uppercase">Platforma elearningowa</h3>
              <p className="text-info">Uczy. Bawi. Motywuje. Analizuje. Powtarza. Zachęca. Wielofunkcyjna platforma LEKkiego Kursu to sposób na sukces</p>
            </div>
            <div className="grid-col-4 grid-flex-column content-wrapper">
            <div className="img-wrapper">
              <img src={info09} alt="zdjęcie informacyjne" className=""/>
              </div>
              <h3 className="text-uppercase">Pomoc moderatorów</h3>
              <p className="text-info">Moderatorzy to osoby pomagające kursantom rozwiać wszystkie niepewności. Odpowiadają na pytania. Dzielą się swoją wiedzą. Motywują do dalszej nauki. To oni kryją się za drugą stroną monitora.</p>
            </div>
          </div>
          <div className="line-separator line-separator-bottom">
            <a className='button-link button-link__danger button-link-md  btn' href="https://lekkikurs.pl/loginNew.php" >Poznaj kurs od środka</a>
          </div>
        </div>
        
        <div className="content-grid__section section-home-charts">

          <div className="line-separator line-separator-top">
            <p className="text-info text-uppercase">Liczby mówią same za siebie</p>
            </div>
            <h2 className="text-title">Wyniki naszych kursantów</h2>

          
          <div className="grid-col-12 wrapper">
            <div className="grid-col-6">
              <h3>Kursanci Lekkiego Kursu z Wynikiem <br /> powyżej średniej krajowej</h3>
              <img src={chart1} alt="wykres wyniku" />
            </div>
          
            <div className="grid-col-6">
              <h3>Zdawalność wśród <br /> kursantów LEKkiego Kursu</h3>
              <img src={chart2} alt="wykres zdawalności" />
            </div>
            
            <div className="grid-col-12">
            <p >
              <a className='button-link button-link__danger button-link-lg btn' href="https://lekkikurs.pl/loginNew.php" > Zapisz się teraz</a>
            </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Opinie;
