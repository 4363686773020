import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Navbar from "./navbar";
import Fb from "../img/fb.png";
import Ig from "../img/ig.png";
import Logo from "../img/logo.png";
import axios from "axios";

function Header() {
  const [online, isOnline] = useState(false);
  const url = "https://lekkikurs.pl/includes/php/grywalizacja/";

  useEffect(() => {
    (async function result() {
      await axios(url + "isOnline.php").then((res) => {
        if (res.data == "online") {
          isOnline(true);
        }
      });
    })();
  }, []);

  // isOnline(true);

  return (
    <header
      className={
        window.location.pathname === "/grywalizacja"
          ? "main-header__top--none"
          : "main-header__top"
      }
    >
      <div className="main-header__top-inner wrapper grid-col-12">
        <div className="main-header__logo grid-col-3">
          <a href="https://lekkikurs.pl">
            <img src={Logo} className="main-header__logo-img" alt="Logo" />
          </a>
        </div>
        <div className="main-header__content grid-col-9">
          <a
            href="https://www.facebook.com/lekkikurs/"
            target="_blank"
            className="main-footer__link"
          >
            <img
              src={Fb}
              className="main-header__image-social btn"
              alt="facebook"
            />
          </a>
          <a
            href="https://www.instagram.com/lekkikurs/"
            target="_blank"
            className="main-footer__link"
          >
            <img
              src={Ig}
              className="main-header__image-social btn"
              alt="Instagram"
            />
          </a>
          <a
            href="https://lekkikurs.pl/rejestracja"
            className="button-link button-link__main btn text-uppercase"
          >
            {" "}
            rejestracja{" "}
          </a>
          <a
            href="https://lekkikurs.pl/loginNew"
            className="button-link button-link__secondary btn text-uppercase"
          >
            {" "}
            zaloguj się
          </a>
          {/*<a
            href="https://lekkikurs.pl/zakupy"
            className="button-link button-link__main btn text-uppercase"
          >*/}
          <a
            href="/zakupy"
            className="button-link button-link__main btn text-uppercase"
          >
            {" "}
            cennik
          </a>
          <Navbar />
        </div>
      </div>
      <div className="button-float">
      {
       !online && <a href="https://lekkikurs.pl/loginNew.php">Zapisz się</a>
      }
      </div>
    </header>
  );
}

export default Header;
