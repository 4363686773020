import React, {useState, useEffect} from 'react';
import './styles/global.scss';
import './styles/themes/default/theme.scss';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Header from './components/header';
import Footer from './components/footer';
import Home from './pages/homepage';
import About from './pages/opinie';
import Help from './pages/pomoc';
import Shopping from './pages/zakupy';
import Teacher from './pages/wykladowcy';
import Game from './pages/grywalizacja/';
import Notfound from './pages/404/';

const App = () => {
  return (
    <Router>
    <div className="App">
      <Header />
      <div className="mainContent">
          <Switch>
            <Route  exact path="/" component={Home} />
            <Route  path="/home" component={About} />
            <Route  path="/help" component={Help} />
            <Route  path="/zakupy" component={Shopping} />
            <Route  path="/wykladowcy" component={Teacher} />
            <Route  path="/grywalizacja" component={Game} />
            <Route  component={Notfound} />
          </Switch>
        </div>
        <Footer />
    </div>
    </Router>
  );
}

export default App;
