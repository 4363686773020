import React from 'react';
import footer from '../img/footer.png';
import FooterNavbar from './footerNav';

function Footer() {
  return (
    <div
      className={
        window.location.pathname === '/grywalizacja'
          ? 'main-footer-none'
          : 'main-footer'
      }
    >
      <div className="main-footer__top grid-col-12 wrapper">
        <div className="grid-col-6">
          <h2>Nauka z LEKkim Kursem to tylko</h2>
          <h3 className="btn-content">3h dziennie przez 30 dni</h3>
          <p className="text-uppercase">nie zwlekaj, zapisz sie już dziś!</p>
        </div>
        <img src={footer} alt="LEK" />
      </div>

      <div className="main-footer__bottom grid-col-12">
        <div className="main-footer__bottom-content">
          <div className="grid-col-6 main-footer__bottom-contact">
            <p>Problem?</p>
            <h3>
              <a href="https://lekkikurs.pl/faq.php">kliknij tutaj</a>
            </h3>
          </div>
          <div className="grid-col-6 main-footer__bottom-info">
            <p>LEKki Kurs</p>
            <h3>
              ul. Szafera 190,
              <br />
              71-245 Szczecin
            </h3>
          </div>
        </div>

        <div className="main-footer__bottom-nav grid-col-12">
          <FooterNavbar />
        </div>
      </div>
    </div>
  );
}

export default Footer;
