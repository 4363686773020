import React , { useState, useEffect } from 'react';

const Loader = ({description}) => {
  return (
    <div>
     <h4>{description}</h4>
     <div className="lds-ripple"><div></div><div></div></div>
    </div>
    )
}

    




export default Loader;