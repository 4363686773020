import React from 'react';

function Notfound() {
  return (
    <div className="App">
     <h1>Nie znaleźliśmy strony której szukasz.</h1>
    </div>
  );
}

export default Notfound;
