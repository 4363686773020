import React from 'react';

function Wykladowcy() {
  return (
    <div className="App">
     <h1>Wykładowcy</h1>
    </div>
  );
}

export default Wykladowcy;
