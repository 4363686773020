import React from 'react';

function Pomoc() {
  return (
    <div className="App">
     <h1>Pomoc</h1>
    </div>
  );
}

export default Pomoc;
