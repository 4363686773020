import React from 'react';
import powerIcon from '../../img/start button oturn on.png';
import marked from 'marked';

const Offer = ({ data }) => {
  const {
    title,
    icon,
    listItem,
    listItem2,
    informationsWithAsterisk
  } = data.fields;

  const list = marked(listItem);
  const list2 = listItem2 !== undefined ? marked(listItem2) : undefined;
  const iconImage = icon.fields.file.url;

  return (
    <div className="offer__section">
      <div className="offer__heading">
        <img src={iconImage} alt="" />

        <div className="offer__heading--inner wrapper--oferta">
          <h3>{title}</h3>
          <div className="offer__filler" />
        </div>
      </div>

      <div className="offer__section--inner">
        <ul
          className="offer__content grid-col-5"
          dangerouslySetInnerHTML={{ __html: list }}
        />

        {listItem2 !== undefined && (
          <ul
            className="offer__content grid-col-5"
            dangerouslySetInnerHTML={{ __html: list2 }}
          />
        )}
      </div>
      {informationsWithAsterisk !== undefined && (
        <p className="offer__asterix"> {informationsWithAsterisk} </p>
      )}
    </div>
  );
};

export default Offer;
