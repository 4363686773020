import React from "react";
import styles from "./styles.scss";
import logo from "../../img/logo.png";

const header = () => {
  return (
    <div id="topnav--wrapper">
      <div className="col-12" id="topnav">
        <a href="#" className="mobileOnly">
          <i className="fas fa-bars"></i>
        </a>
        <a href="https://lekkikurs.pl/">Start</a>
        <a href="https://lekkikurs.pl/filmy.php">Filmoteka</a>
        <a href="https://lekkikurs.pl/galeria.php" className="mobileNone">
          Mnemoniki
        </a>
        <a href="https://lekkikurs.pl/quest_main.php">Baza Pytań</a>
        <a href="https://lekkikurs.pl/wykladowcy.php" className="mobileNone">
          Wykładowcy
        </a>
        <a href="https://lekkikurs.pl/faq.php">Pomoc</a>
        <a href="https://lekkikurs.pl/analizator.php" className="mobileNone">
          Analizator
        </a>
      </div>
      <div className="col-12" id="floatnav">
        <div id="navimg" className="col-1">
          <a href="https://lekkikurs.pl/">
            <img src={logo} className="additional-logo" />
          </a>
        
        
        </div>
      </div>
    </div>
  );
};



export default header;
