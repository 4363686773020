import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import menuIcon from '../img/menu.png';
function Navbar() {

  const [ display, setDisplay ] = useState(false);


  return (
    <div className="main-header__navbar">
      <img src={ menuIcon } className="main-header__navbar-toggle btn" onClick={ () => setDisplay(true) } ></img>
      <div className={ display ? "main-header__navbar-wrapper" : "main-header__navbar-wrapper main-header__navbar-wrapper--close" }>
        <ul>
          <span onClick={ () => setDisplay(false) } className='main-header__navbar-close'>x</span>
          <li>
            <Link to="/" className="main-header__link">
              Start
        </Link>
          </li>
          <li>
            <a className="main-header__link" href="https://lekkikurs.pl/filmy.php">Platforma</a>
          </li>
          <li>
            <a className="main-header__link" href="https://lekkikurs.pl/opinie.php">Opinie</a>
          </li>
          <li>
            <a className="main-header__link" href="https://lekkikurs.pl/lekkiKurs.pdf" target="__blank">Skrypt</a>
          </li>
          <li>
            <a className="main-header__link" href="https://lekkikurs.pl/faq.php">Pomoc</a>
          </li>
          <li>
            <Link to="/zakupy" className="main-header__link">
              Zakupy
        </Link>
          </li>
        </ul>
        <a href="https://lekkikurs.pl/loginNew.php" className="button-link button-link--mobile button-link__main btn text-uppercase"> rejestracja </a>
        <a href="https://lekkikurs.pl/loginNew.php" className="button-link button-link--mobile button-link__secondary btn text-uppercase"> zaloguj się</a>
      </div>
    </div>
  );
}

export default Navbar;