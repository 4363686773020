import React from 'react'
import ScriptTile from './ScriptTile'
import calendar15 from '../../img/kalendarz.png';

const ScriptProduct = ({ scriptProductData }) => {
  const { title, price, accessibility, image, description, realisationTime } = scriptProductData.fields

  const img = image.fields.file.url;

  return (
    <div className="offer__wrapper">
      <div className="offer__section45 offer__section ">
        <div className="offer__heading">
          <img src={ calendar15 } alt="from-15-11" />
          <div className="offer__heading--inner wrapper--oferta">
            <h3>Od { accessibility }:</h3>
            <div className="offer__filler" />
          </div>
        </div>
        <div className="offer__content">
          <p className="offer__text">
          { description }
          <br />
            <span className="highlight">{ realisationTime }</span>
          </p>
          
        </div>
      </div>
      
      <ScriptTile {...{title, price, img, accessibility}}/>
    </div>
  )
}

export default ScriptProduct
