import React from 'react'
import Offer from './Offer'

function Offers({ contentfullData }) {
  const offerData1 = contentfullData.filter((item) => item.sys.contentType.sys.id === "offer")
  const offerData2 = contentfullData.filter((item) => item.sys.contentType.sys.id === "offer2")
  const offerData3 = contentfullData.filter((item) => item.sys.contentType.sys.id === "offer3")
  const offerData4 = contentfullData.filter((item) => item.sys.contentType.sys.id === "offer4")
  const offerData5 = contentfullData.filter((item) => item.sys.contentType.sys.id === "offer5")

  return (
    <div className="wrapper--oferta"> 

      {offerData1.length !== 0 && <Offer data={ offerData1[0] } /> }
      {offerData2.length !== 0 && <Offer data={ offerData2[0] } /> }
      {offerData3.length !== 0 && <Offer data={ offerData3[0] } /> }
      {offerData4.length !== 0 && <Offer data={ offerData4[0] } /> }
      {offerData5.length !== 0 && <Offer data={ offerData5[0] } /> }

    </div>
  );
}

export default Offers
