import React from 'react'
import ScriptProduct from './ScriptProduct'
import TimeAccessProduct from './TimeAccessProduct'


const TimeAccess = ({ myRef1, setParams, contentfullData }) => {
  const timeAccessData = contentfullData.filter((item) => item.sys.contentType.sys.id === "timeAccess")

  const scriptProductsData = contentfullData.filter((item) => item.sys.contentType.sys.id === "scriptProduct")

 
  return (
    <>
      <div className="wrapper--oferta">
        <h2 ref={ myRef1 }>Dostęp czasowy:</h2>
      </div>

      <div className="wrapper--oferta">
        { timeAccessData.map((timeAccessProductData, i) => {
          return (
            <TimeAccessProduct {...{timeAccessProductData, setParams, key:i}}/>
          )
      })}
        
        { scriptProductsData.map((scriptProductData, i) => (
          <ScriptProduct {...{scriptProductData, key:i, setParams}}/>
      ))}
      </div>
    </>
  )
}

export default TimeAccess
