import React from "react";
import { Link } from "react-router-dom";
import Pdf from '../pdf/REGULAMIN-platforma.pdf';
import POLITYKA from '../pdf/POLITYKA.pdf';

function FooterNav() {
  return (
    <ul className="main-footer__navbar wrapper">
      <li>
        <a href="https://lekkikurs.pl"  className="main-footer__link">
          O kursie
        </a>
      </li>
      <li>
        <Link to="/zakupy" className="main-footer__link">
          Cennik
        </Link>
      </li>
      <li>
        <Link to="/home" className="main-footer__link">
          Opinie
        </Link>
      </li>
      <li>
         <a href={Pdf} target="_blank" className="main-footer__link">Regulamin</a>
      </li>
      <li>
        <a href={POLITYKA} target="_blank" className="main-footer__link">Polityka prywatności</a>
      </li>
      <li>
         <a href="https://lekkikurs.pl/faq.php"  className="main-footer__link">Kontakt</a>
      </li>
    </ul>
  );
}

export default FooterNav;