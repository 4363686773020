import React from 'react'
import BenefitsTile from './BenefitsTile'

const YoursBenefits = ({ contentfullData }) => {
  const benefitsData = contentfullData.filter((item) => item.sys.contentType.sys.id === "benefitsTile")

  return (
    <>
      <div className="wrapper--oferta">
        <h2>Co zyskasz z LEKkim?</h2>
      </div>

      <div className="icon-tiles">
        <div className="icon-group">
          { benefitsData.splice(0, 2).map((benefitTileData, i) => {
            return (
              <BenefitsTile { ...{ benefitTileData, key:i} } />
            )
          })}
        </div>

        <div className="icon-group">
          { benefitsData.map((benefitTileData, i) => {
            return (
              <BenefitsTile { ...{ benefitTileData, key:i} } />
            )
          }) }
        </div>
      </div>
    </>
  )
}

export default YoursBenefits
